import { orderVnpayReturn } from "core/redux/actions/orderAction";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Loading from "share/loading";
import SuccessTransaction from "../components/successTransaction";
import FailedTransaction from "../components/failedTransaction";
import { isEmpty } from "lodash";

function VNPayReturn() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      // do useEffect
      if (location.search) {
        const query = {};
        const queryStringWithoutQuestionMark = location.search.substring(1); // Remove the leading '?'
        const keyValuePairs = queryStringWithoutQuestionMark.split("&");
        keyValuePairs.forEach((pair) => {
          const [key, value] = pair.split("=");
          query[key] = value;
        });
        // dispatch(getOrderDetails(query["vnp_TxnRef"]));
        dispatch(orderVnpayReturn(query, setStatus));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      ignore = true;
    };
  }, [location.search]);

  const renderbody = () => {
    return status.RspCode !== "00" ? (
      <FailedTransaction status={status} />
    ) : (
      <SuccessTransaction order={status.order} />
    );
  };
  return isEmpty(status) ? <Loading /> : renderbody();
}

export default VNPayReturn;
