import {
  ORDER_CREATE_PAYMENT_URL,
  FETCH_ORDER_FAILED,
  FETCH_ORDER_REQUESTING,
  GET_ORDER_DETAILS,
} from "core/redux/constant/orderConstants";
// Khởi tạo State
const initialState = {
  paymentURL: "",
  orderDetail: {},
  orderList: [],
  orderLoading: false,
};

export const OrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDER_REQUESTING: {
      return { ...state, orderDetail: {}, orderLoading: true };
    }
    case FETCH_ORDER_FAILED: {
      console.log("FETCH_ORDER_FAILED");
      return {
        ...state,
        orderLoading: false,
        error: action.payload,
      };
    }
    case GET_ORDER_DETAILS: {
      return {
        orderDetail: action.payload.data,
      };
    }
    case ORDER_CREATE_PAYMENT_URL: {
      return { ...state, paymentURL: action.payload };
    }
    default: {
      return { ...state };
    }
  }
};
