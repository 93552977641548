import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { optionsRadioField, questionList } from "./const";
import { FastField, Form, Formik } from "formik";
import InputField from "app/components/customField/inputField";
import { styled } from "@mui/material/styles";
import {
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import axios from "axios";
import * as Yup from "yup";
import Loading from "share/loading";
import WordByWord from "app/components/wordByWord";
import "./styles.scss";
const ButtonSubmit = styled(Button)`
  color: black;
  font-size: 17px;
  // background-image: linear-gradient(rgb(255, 0, 191), #8420be);
  background: rgb(0, 171, 85);
  :hover {
    color: white;
    background-image: linear-gradient(#8420be, rgb(255, 0, 191));
    background: rgb(0, 171, 85);
  }
`;
function QuestionForUser(props) {
  var result = [];
  var [radioArray, setRadioArray] = useState(Array(questionList.length));
  const [step, setStep] = useState(0);
  const [quizs, setQuizs] = useState([]);
  const [question, setQuestion] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [disableRadio, setDisableRadio] = useState(false);
  const [radioValue, setRadioValue] = useState("");
  useEffect(() => {
    // videoId !== props.video._id && handleReset();
    handleReset();
  }, []);

  const initialValues = {
    email: "",
    first_name: "",
    phone: "",
    address: "",
  };
  const phoneRegExp = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Vui lòng nhập trường này")
      .max(255)
      .email("Email chưa đúng"),
    phone: Yup.string()
      .required("Vui lòng nhập trường này")
      .matches(phoneRegExp, "Số điện thoại chưa đúng."),
  });
  const handleReset = () => {
    setStep(0);
    setQuizs(questionList);
    setQuestion(questionList[0]);
    setQuestionIndex(0);
    setLoading(false);
    setError("");
    setDisableRadio(false);
    setSelectedAnswer([]);
  };
  const handleQuestion = (type) => {
    if (type === "pre") {
      setError("");
      setQuestionIndex(questionIndex - 1);
      setQuestion(questionList[questionIndex - 1]);
      if (
        selectedAnswer[questionIndex - 1].value ===
        questionList[0].options[1].value
      ) {
        setDisableRadio(false);
      } else {
        setDisableRadio(true);
        setRadioValue(radioArray[questionIndex - 1]);
      }
    } else if (questionList?.length > questionIndex) {
      if (isEmpty(selectedAnswer[questionIndex])) {
        setError("Xin hãy chọn đầy đủ kết quả");
        return;
      } else {
        if (
          selectedAnswer[questionIndex].value ===
            questionList[0].options[0].value &&
          isEmpty(radioValue)
        ) {
          setError("Xin hãy chọn đầy đủ kết quả");
          return;
        } else {
          setError("");
        }
      }
      setQuestionIndex(questionIndex + 1);
      setQuestion(questionList[questionIndex + 1]);
      radioArray[questionIndex] = radioValue;

      setRadioArray((prevState) => {
        const newArray = [...prevState];
        newArray[questionIndex] = radioValue;
        return newArray;
      });

      setError("");
      setRadioValue("");
      if (!isEmpty(selectedAnswer[questionIndex + 1])) {
        if (
          selectedAnswer[questionIndex + 1].value ===
          questionList[0].options[1].value
        ) {
          setDisableRadio(false);
        } else {
          setDisableRadio(true);
          setRadioValue(radioArray[questionIndex + 1]);
        }
      } else {
        setDisableRadio(false);
      }
      if (questionList?.length === questionIndex + 1) {
        setStep(1);
      }
    } else {
      setError("Error");
    }
  };
  const handleOnClick = (item) => {
    if (question.options[0].value === item) {
      setDisableRadio(true);
    } else {
      setDisableRadio(false);
    }
  };
  const updateRadioSelect = (ev, value) => {
    setRadioValue(value);
  };
  const formattedAnswer = () => {
    for (let i = 0; i < radioArray.length; i++) {
      var temp = `${(i + 1).toString()}-${selectedAnswer[i].value}-${
        radioArray[i]
      }`;
      result.push(temp);
    }
  };
  const handleRegister = async (data) => {
    formattedAnswer();

    data["tags"] = ["Vas-QuestionTestBrand"];
    data["import_by_tag_name"] = true;
    data["questiontestbrand"] = JSON.stringify(result);
    await axios({
      method: "POST",
      url: "https://api.service.ladiflow.com/1.0/customer/create-or-update",
      headers: {
        "Api-Key": "a21928473afdf60440c8adddec916036aac285ce560b0133",
      },
      data: data,
    })
      .then((response) => {
        setLoading(false);
        setStep(2);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const renderQuestion = () => {
    return (
      <>
        <>
          <div className="d-flex justify-content-end">
            <h5
              style={{
                color: "#60d600",
                width: "100px",
                textAlign: "right",
              }}
            >
              {quizs.indexOf(question) + 1} / {quizs?.length}
            </h5>
          </div>
          <div className="d-flex justify-content-between gap-md-3">
            <p
              className="mb-2 fs-normal lh-base text-black font-weight-bold"
              style={{ fontSize: "1.5rem" }}
            >
              {/* {question?.question} */}
              <WordByWord text={question?.question} index={questionIndex} />
            </p>
          </div>

          {error && <p className="text-danger ">{error}</p>}

          <div className="container">
            <div className="row">
              {question?.options?.length > 0 &&
                question?.options?.map((item, index) => (
                  <button
                    key={index}
                    className={`option w-100 text-center text-white py-2 px-3 mt-3 mx-3 rounded btn btn-dark col-sm
                        ${
                          selectedAnswer[questionIndex]?.value === item.value &&
                          "bg-success"
                        }`}
                    onClick={(event) => {
                      let newAnswer = [...selectedAnswer];
                      newAnswer[questionIndex] = {
                        question: question.question,
                        value: question.options[index].value,
                      };
                      setSelectedAnswer(newAnswer);
                      handleOnClick(question.options[index].value);
                    }}
                    // disabled={correctAnswer !== -1 ? true : false}
                  >
                    {item.value}
                  </button>
                ))}
            </div>
            <div
              className={`box-wrapper ${disableRadio ? "visible" : "hidden"}`}
            >
              <div className={`question-for-user-box`}>
                <FormGroup style={{ alignItems: "center", marginTop: "10px" }}>
                  <FormLabel style={{ fontSize: "14px" }}>
                    Với thang điểm từ 0 đến 10 anh chị đánh giá như thế nào về
                    khía cạnh này
                  </FormLabel>
                  <FormLabel style={{ fontSize: "14px" }}>
                    0 — 10 ( 0 : KHÔNG HÀI LÒNG; 10 CỰC KỲ HÀI LÒNG )
                  </FormLabel>

                  <RadioGroup
                    value={radioValue}
                    onChange={updateRadioSelect}
                    name="radio-buttons-group"
                    aria-labelledby="demo-radio-buttons-group-label"
                    row="true"
                    alignItems="center"
                  >
                    {optionsRadioField?.map((o) => (
                      <FormControlLabel
                        value={o.value}
                        control={<Radio />}
                        label={o.label}
                        key={o.value - 1}
                      />
                    ))}
                  </RadioGroup>
                </FormGroup>
              </div>
            </div>
          </div>
        </>
        {questionIndex + 1 !== quizs.length ? (
          <div className="mt-3 d-flex justify-content-around">
            {questionIndex !== 0 && (
              <button
                className="btn py-2 w-25 bg-primary  text-light fw-bold"
                onClick={(event) => {
                  event.preventDefault();
                  handleQuestion("pre");
                }}
                disabled={!selectedAnswer}
              >
                Câu hỏi Trước
              </button>
            )}
            <button
              className="btn py-2  bg-primary text-light fw-bold"
              onClick={(event) => {
                handleQuestion("next");
              }}
              disabled={!selectedAnswer}
            >
              Câu hỏi tiếp theo
            </button>
          </div>
        ) : (
          <>
            <button
              className="btn py-2 w-100 mt-3 bg-primary text-light fw-bold"
              onClick={(event) => {
                handleQuestion("next");
              }}
            >
              Hoàn Thành & Xem Kết Quả
            </button>
          </>
        )}
      </>
    );
  };

  const renderResult = () => {
    return (
      <div>
        <div
          className="title-h2 text-center mb-3"
          style={{ color: "rgb(0, 171, 85)" }}
        >
          Hãy để lại Họ tên, Email & SĐT để nhận kết quả
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleRegister}
            enableReinitialize
          >
            {(formikProps) => {
              const { values, errors, touched } = formikProps;

              console.log({ values, errors, touched });

              return (
                <Form className="row mt-4 py-3 form-body">
                  <div className="col-8 offset-2">
                    <FastField
                      name="first_name"
                      component={InputField}
                      label="Tên đầy đủ"
                      placeholder="Nhập tên"
                      className="w-100 mb-4"
                    />
                  </div>

                  <div className="col-8 offset-2">
                    <FastField
                      name="email"
                      component={InputField}
                      label="Email"
                      placeholder="Nhập email"
                      className="w-100 mb-4"
                    />
                  </div>

                  <div className="col-8 offset-2">
                    <FastField
                      name="phone"
                      component={InputField}
                      label="Số điện thoại"
                      placeholder="Nhập số điện thoại"
                      className="w-100 mb-4"
                      autocomplete
                    />
                  </div>
                  {/* <div className="col-8 offset-2">
                    <FastField
                      name="address"
                      component={InputField}
                      label="Địa chỉ"
                      placeholder="Nhập địa chỉ"
                      className="w-100 mb-4"
                    />
                  </div> */}
                  <div className="col-12 justify-content-center d-flex">
                    <ButtonSubmit
                      type="submit"
                      className=""
                      disabled={isEmpty(errors) && values.email ? false : true}
                    >
                      Đăng ký
                      {loading && <div className="loader ml-1"></div>}
                    </ButtonSubmit>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  };
  return (
    <section
      className=" text-white quiz"
      style={{
        // backgroundImage: `url(${FlowerBg1})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="container">
        <div className="row min-vh-100 align-items-center justify-content-center py-5">
          <div className="col-md-8 ">
            <div
              className="card p-4"
              style={{ background: "white", borderColor: "#646464" }}
            >
              {loading ? (
                <Loading />
              ) : step === 0 ? (
                renderQuestion()
              ) : step === 1 ? (
                renderResult()
              ) : (
                <div
                  className="title-h2 text-center "
                  style={{ color: "rgb(0, 171, 85)" }}
                >
                  Cám ơn bạn, chúng tôi sẽ liên hệ để gửi kết quả cho bạn nhé!
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default QuestionForUser;
