import { Button } from "@mui/material";
import {
  removeCourseFromCart,
  removeCourseOnlineFromCart,
} from "core/redux/actions/cartAction";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import FormatNumberic from "share/formatNumber";

export const RenderCourseOnline = (props) => {
  const product = props.product;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(removeCourseOnlineFromCart(product, true));
  }, []);

  return (
    <div className="p-3 d-flex justify-content-center">
      <div className="col-3 ">
        <img src={product.img} alt="image-course" className="rounded" />
      </div>
      <div className="">
        <div>{product.name}</div>
        <div className="product-price">{product.pharse}</div>
        <div className="text-danger d-flex justify-content-between align-items-center">
          <FormatNumberic
            num={product.price}
            style={{ fontWeight: "bolder" }}
          />
          {props.orderDetail && (
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                props?.handleRemoveOrder(product);
              }}
            >
              Xoá
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
export const RenderCourseOffline = (props) => {
  const product = props.product;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(removeCourseFromCart(product, true));
  }, []);
  return (
    <div className="p-3 d-flex justify-content-center">
      <div className="col-3">
        <img src={product.img} alt="image-course" className="rounded" />
      </div>
      <div className="">
        <div className="d-flex justify-content-between">
          <div>
            <div>{product.name}</div>
            <div className="product-price">Loại vé: {product.ticket}</div>
            <div
              className="d-flex justify-content-between text-danger"
              style={{ fontWeight: "bolder" }}
            >
              <FormatNumberic num={product.price} />

              {/* <div className="product-count">
                  <RemoveIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleCountOfCourse(product, "remove");
                    }}
                  />
                  <div className="break-line "></div>
                  <div className="px-2">{product.count}</div>
                  <div className="break-line"></div>
                  <AddIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleCountOfCourse(product, "add");
                    }}
                  />
                </div> */}
            </div>
          </div>
          <div className="text-danger d-flex justify-content-between align-items-center">
            {props.orderDetail && (
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  props?.handleRemoveOrder(product);
                }}
              >
                Xoá
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
