import React from "react";
import { statusMessage } from "app/const/statusPayment";
import { db } from "app/const/firebase";

import "./styles.scss";

function FailedTransaction({ status, timeStamp }) {
  const statusMgs = statusMessage.find((code) => code.code == status.RspCode);
  React.useEffect(() => {}, []);

  return (
    <div className="wrapper-content container mb-5 mt-5">
      <i className="fal fa-times"></i>
      <div className="product">
        <div className="content-body_product">
          <h3 className="mt-4">Thanh toán của bạn thất bại</h3>
          <p className="mt-4">{statusMgs?.message}</p>
        </div>
      </div>
    </div>
  );
}

export default FailedTransaction;
