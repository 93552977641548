import {
  ORDER_CREATE_PAYMENT_URL,
  FETCH_ORDER_FAILED,
  FETCH_ORDER_REQUESTING,
  GET_ORDER_DETAILS,
} from "../constant/orderConstants";
import axios from "axios";
import {
  API_ENDPOINT,
  API_ORDER_GET_DETAILS,
  API_ORDER_CREATE_PAYMENT_URL,
  API_ORDER_VNPAY_RETURN,
  API_ORDER_VNPAY_IPN,
} from "app/const/Api";

export const getOrderDetails = (vnp_TxnRef) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_ORDER_REQUESTING });
    try {
      await axios({
        url: API_ENDPOINT + API_ORDER_GET_DETAILS,
        method: "GET",
        params: { vnp_TxnRef },
      }).then((res) => {
        dispatch({ type: GET_ORDER_DETAILS, payload: res.data });
      });
    } catch (error) {
      dispatch({ type: FETCH_ORDER_FAILED, payload: error });
    }
  };
};
export const orderCreatePaymentUrl = (
  vnp_BankCode,
  ipAddr,
  amount,
  checkout,
  user_id
) => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_ORDER_REQUESTING,
    });
    try {
      await axios({
        url: API_ENDPOINT + API_ORDER_CREATE_PAYMENT_URL,
        method: "GET",
        params: {
          ipAddr,
          vnp_BankCode,
          amount,
          checkout: JSON.stringify(checkout),
          user_id: user_id,
        },
      }).then((res) => {
        console.log("res", res);
        window.open(res.data.data, "_blank");
      });
    } catch (error) {
      dispatch({ type: FETCH_ORDER_FAILED, payload: error });
    }
  };
};

export const orderVnpayIpn = (data, setStatus) => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_ORDER_REQUESTING,
    });
    try {
      await axios({
        url: API_ENDPOINT + API_ORDER_VNPAY_IPN,
        method: "GET",
        params: data,
      }).then((res) => {
        setStatus(res.data);
      });
    } catch (error) {
      dispatch({ type: FETCH_ORDER_FAILED, payload: error });
    }
  };
};
export const orderVnpayReturn = (data, setStatus) => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_ORDER_REQUESTING,
    });
    console.log("Preparing to make axios request 2");

    axios({
      url: API_ENDPOINT + API_ORDER_VNPAY_RETURN,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
      params: data,
    })
      .then((res) => {
        console.log("Response received:", res);
        setStatus(res.data);
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
    // try {
    //   console.log("orderVnpayReturn");
    //   console.log("url:", API_ENDPOINT + API_ORDER_VNPAY_RETURN);
    //   console.log("data", data);
    //   console.log(" axios");
    //   await axios({
    //     url: API_ENDPOINT + API_ORDER_VNPAY_RETURN,
    //     method: "GET",
    //     params: data,
    //   })
    //     .then((res) => {
    //       console.log("res", res);
    //       setStatus(res.data);
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });

    //   console.log("after axios");
    // } catch (error) {
    //   console.log("error", error);
    //   dispatch({ type: FETCH_ORDER_FAILED, payload: error });
    // }
  };
};
