import React, { useEffect, useState } from "react";
// Action
import { orderCreatePaymentUrl } from "core/redux/actions/orderAction";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

function CheckoutCart(props) {
  const dispatch = useDispatch();
  const [protocol, setProtocol] = useState("");
  const { orderLoading } = useSelector((state) => state.order);
  useEffect(() => {
    let ignore = true;
    if (ignore) {
      // do useEffect
      // dispatch(orderCreatePaymentUrl(protocol));
      getIPLocal();
    }

    return () => {
      ignore = false;
    };
  }, []);

  useEffect(() => {
    dispatch(orderCreatePaymentUrl(protocol));
  }, [protocol]);
  const getIPLocal = async () => {
    try {
      await axios
        .get("https://api.ipify.org")
        .then((res) => setProtocol(res.data))
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  return orderLoading ? <div>loading</div> : <></>;
}

export default CheckoutCart;
