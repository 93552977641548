import axios from "axios";
import _, { isEmpty } from "lodash";

import { getDetailScheduleCourseOnlineAction } from "core/redux/actions/scheduleCourseOnlineAction";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SingleCourseMedia from "../components/singleCourseMedia";
import { Box, Modal } from "@mui/material";
import Loading from "share/loading";
import { useTranslation } from "react-i18next";
import useSiteTitle from "core/hooks/useSiteTitle";
import qrZalo from "assets/images/course/course-offline/khoi-dau-thinh-vuong/qr-zalo.jpg";
import qrVIB from "assets/images/qrVIB.jpg";
import { addCourseOnlineToCart } from "core/redux/actions/cartAction";
import bannerSingleCourseMedia from "assets/images/source/course-media/single-course-media.jpg";
function Tower1() {
  const { t } = useTranslation("common");
  useSiteTitle(t("tower1"));

  const [userData, setUserData] = useState({});
  const [pharse, setPharse] = useState("");
  const [detailCourseOnline, setDetailCourseOnline] = useState({});
  const { detailScheduleCourseOnline } = useSelector(
    (state) => state.scheduleCourseOnline
  );
  const { userInfo } = useSelector((state) => state.auth);
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    loadData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // check login -> check user is in the student list
    let checkPermission = "";
    if (userInfo) {
      if (detailScheduleCourseOnline?.studentList?.length > 0) {
        checkPermission = detailScheduleCourseOnline.studentList.find(
          (ele) => ele._id === userInfo._id
        );
      }
    }
    if (isEmpty(checkPermission)) {
      setUserData();
    } else {
      setUserData(checkPermission);
    }
    if (!isEmpty(detailScheduleCourseOnline)) {
      setDetailCourseOnline(detailScheduleCourseOnline.courseOnline);
    }
  }, [userInfo, detailScheduleCourseOnline]);
  // Fetch Table Data
  const loadData = () => {
    dispatch(getDetailScheduleCourseOnlineAction("668f99255948901f707cd414"));
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleRegister = async (e, pharse) => {
    e.preventDefault();
    if (!token) {
      navigate("/dang-nhap", { state: { from: location } });
    } else {
      let data = {
        schedule_id: detailScheduleCourseOnline._id,
        course_id: detailCourseOnline._id,
        name: detailCourseOnline.name,
        permission: pharse.permission,
        title: pharse.title,
        pharse: pharse.pharse,
        price: pharse.price,
        type: "courseOnline",
        img: bannerSingleCourseMedia,
        checkout: false,
        count: 1,
      };
      // dispatch(addCourseOnlineToCart(data));
      handleSendData(pharse);
    }
  };

  const handleSendData = async (pharse) => {
    const data = _.cloneDeep(userInfo);
    data["tags"] = ["register-tower1", pharse.pharse];
    data["import_by_tag_name"] = true;
    await axios({
      method: "POST",
      url: "https://api.service.ladiflow.com/1.0/customer/create-or-update",
      headers: {
        "Api-Key": "a21928473afdf60440c8adddec916036aac285ce560b0133",
      },
      data,
    })
      .then((response) => {
        setPharse(pharse);
        setOpenModal(true);
        console.log("response", response);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleRedirect = (pharseIndex, videoIndex) => {
    navigate("/chuong-trinh-huan-luyen/media/video-streaming/_id=", {
      state: {
        from: location,
        pharseIndex: pharseIndex,
        videoIndex: videoIndex,
        siteTitle: "tower1",
      },
    });
  };
  const handleRedirectZalo = () => {
    window.open("https://zalo.me/0906880917", "_blank");
  };
  const renderModal = () => {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      // width: 100,
      // maxHeight: "100%",
      borderRadius: "40px",
      bgcolor: "background.paper",
      boxShadow: 24,
      "&:focus-visible": {
        outline: "0px solid #1976d2", // Add an outline for keyboard focus
        boxShadow: 12, // Example: different shadow on focus
      },
    };
    return (
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        <Box sx={style}>
          <div
            className="container text-center"
            style={{
              overflow: "auto",
              height: "80vh",
              backgroundColor: "white",
              borderRadius: "40px",
            }}
          >
            <div className="row popup">
              <div className="popup-background"></div>
              <div className="overlay" />
              <div className="col-12 position-relative">
                <div className="row mt-4 d-flex justify-content-center">
                  <h4 style={{ color: "yellow" }}>
                    Cám ơn bạn đã đăng ký.
                    <br /> Soul retreats sẽ phản hồi trong vòng 24h làm việc
                  </h4>
                  <p className="mt-2 mb-2" style={{ lineHeight: 1.5 }}>
                    Anh/Chị vui lòng chuyển khoản với cú pháp: <br />
                    {userInfo?.name + " " + userInfo?.phone + " " + pharse}
                  </p>
                  <p>Ngân hàng VIB</p>
                  <p>Chủ tài khoản: LA HẠ GIANG THANH</p> <p>STK: 798888696</p>
                  <img
                    src={qrVIB}
                    alt="qrVIB"
                    style={{ height: 150, width: 150 }}
                  />
                  <p className="mt-3">
                    ✨ Sau khi hoàn tất thanh toán <br />
                    Bạn vui lòng
                    <h4
                      style={{
                        color: "yellow",
                        marginTop: 15,
                        marginBottom: 15,
                      }}
                    >
                      GỬI BILL THANH TOÁN VÀO ZALO CSKH
                    </h4>
                  </p>
                  <img
                    src={qrZalo}
                    alt=""
                    onClick={() => {
                      handleRedirectZalo();
                    }}
                    style={{ height: 150, width: 150 }}
                  />
                  <p className="mt-3">
                    Trong vòng 24h Soul Retreats sẽ liên hệ cho bạn để xác nhận
                    thông tin.
                  </p>
                  <p>
                    Bạn cũng sẽ nhận được email với thông tin hữu ích về chương
                    trình.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    );
  };
  return detailCourseOnline &&
    detailCourseOnline.lesson &&
    detailCourseOnline.lesson.length > 0 ? (
    <>
      <SingleCourseMedia
        name={detailCourseOnline.name}
        handleRegister={(e, pharse) => handleRegister(e, pharse)}
        numberSudentRate={168}
        description={detailCourseOnline.description}
        willLearn={[
          {
            title: "Thành Tích",
            content:
              "Hiểu rõ những yếu tố cần thiết để đạt được thành tích đỉnh cao và cách tạo ra mục đích, ý nghĩa cho cuộc sống của bạn.",
          },
          {
            title: "Bí Mật của Ba Tâm Trí",
            content:
              "Tìm hiểu về ba phần quan trọng của tâm trí và cách chúng tương tác để ảnh hưởng đến quyết định và hành vi của bạn.",
          },
          {
            title: "Sức Mạnh của Trí Tưởng Tượng",
            content:
              "Khám phá cách sử dụng trí tưởng tượng để định hình tương lai và hiện thực hóa các mục tiêu của bạn.",
          },
          {
            title: "Mô Hình Mắt",
            content:
              "Hiểu về mô hình mắt và cách nó phản ánh trạng thái tinh thần và cảm xúc của bạn.",
          },
          {
            title: "Kiểm Soát Cảm Xúc",
            content:
              "Học cách kiểm soát và quản lý cảm xúc để duy trì trạng thái tinh thần tích cực và ổn định.",
          },
          {
            title: "Mối Quan Hệ",
            content:
              "Khám phá cấu trúc của các mối quan hệ trong cuộc sống và học cách xây dựng, duy trì mối quan hệ lành mạnh và ý nghĩa.",
          },
        ]}
        forWho={[
          "Nhân viên văn phòng",
          "Nhà lãnh đạo, quản lý",
          "Học sinh, sinh viên muốn thành công",
          "Nhà tư vấn, Chuyên gia tâm lý",
          "Người muốn phát triển bản thân",
        ]}
        detailCourseOnline={detailCourseOnline}
        handleRedirect={(pharseIndex, videoIndex) =>
          handleRedirect(pharseIndex, videoIndex)
        }
        userData={userData}
        learnerReview={[
          { title: "Exceeded", rate: 90 },
          { title: "Yes", rate: 40 },
          { title: "Somewhat", rate: 60 },
          { title: "Not Really", rate: 33 },
          { title: "No", rate: 0 },
        ]}
      />
      {renderModal()}
    </>
  ) : (
    // chưa xử lý loading
    <Loading></Loading>
  );
}

export default Tower1;
