import React, { useEffect, useState } from "react";
import { db } from "app/const/firebase";

import "./styles.scss";
import { statusMessage } from "app/const/statusPayment";
import { isEmpty } from "lodash";
import Loading from "share/loading";
import { render } from "@testing-library/react";
import { RenderCourseOffline, RenderCourseOnline } from "./renderCourse";
import { useDispatch } from "react-redux";

function SuccessTransaction(props) {
  const [data, setData] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    handleInput();
  }, [props.order]);

  const handleInput = () => {
    setData(props.order);
  };

  return isEmpty(data) ? (
    <Loading />
  ) : (
    <div className="wrapper-content container mb-5 mt-5">
      <div className="product">
        <div className="d-flex justify-content-center">
          <i className="fal fa-check"></i>
        </div>
        <div className="content-body_product">
          <h3 className="mt-4">thanh toán thành công</h3>
        </div>
      </div>
      <div className="row mt-5">
        <div className="detail-info col-8 offset-2">
          <h3 className="d-flex justify-content-center">
            Thông tin thanh toán
          </h3>
          {data?.product?.map((ele, index) => {
            return ele.type === "courseOffline" ? (
              <RenderCourseOffline
                product={ele}
                vnpayReturn={true}
                key={index}
              />
            ) : ele.type === "courseOnline" ? (
              <RenderCourseOnline
                product={ele}
                vnpayReturn={true}
                key={index}
              />
            ) : (
              <div key={index}></div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default SuccessTransaction;
