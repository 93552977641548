import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { styled } from "@mui/material/styles";
import { Box, Button, Modal } from "@mui/material";
import useSiteTitle from "core/hooks/useSiteTitle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightIcon from "@mui/icons-material/Flight";
import _, { cloneDeep, isEmpty } from "lodash";
import axios from "axios";

import Vimeo from "@u-wave/react-vimeo";
import "./styles/styles.scss";
import Loading from "share/loading";
import {
  getDetailScheduleCourseOnlineAction,
  handleUserAnswerToQuestion,
  handleSendEmailAfterAnswer,
} from "core/redux/actions/scheduleCourseOnlineAction";
import FormatNumberic from "share/formatNumber";

import Quiz from "./components/quiz";

const VideoStreaming = (props) => {
  // useSiteTitle("video_streaming");
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { detailScheduleCourseOnline } = useSelector(
    (state) => state.scheduleCourseOnline
  );
  const { userInfo } = useSelector((state) => state.auth);
  const [data, setData] = useState({});

  const [video, setVideo] = useState({});
  const [pharse, setPharse] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [answerQuestion, setAnswerQuestion] = useState(false);
  const [sendAnswer, setSendAnswer] = useState(false);
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video]);

  useEffect(() => {
    if (userInfo) {
      console.log("detailScheduleCourseOnline", detailScheduleCourseOnline);
      if (detailScheduleCourseOnline?.studentList?.length > 0) {
        const index = detailScheduleCourseOnline.studentList.findIndex(
          (ele) => ele._id === userInfo._id
        );
        if (index !== -1) {
          handleDataInput(detailScheduleCourseOnline?.studentList[index]);
        } else {
          !isEmpty(detailScheduleCourseOnline?.courseOnline) &&
            handleDataInput(detailScheduleCourseOnline.courseOnline);
        }
      } else {
        !isEmpty(detailScheduleCourseOnline?.courseOnline) &&
          handleDataInput(detailScheduleCourseOnline.courseOnline);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailScheduleCourseOnline]);

  const handleDataInput = (data) => {
    setData(data);
    if (location?.state && !sendAnswer) {
      console.log("sendAnswer eror");
      let pharseIndex = location?.state.pharseIndex;
      let videoIndex = location?.state.videoIndex;
      setPharse(data?.lesson[pharseIndex]);
      setVideo(data?.lesson[pharseIndex].videoList[videoIndex]);
    }
    if (sendAnswer) {
      let pharseIndex = data.lesson.findIndex(
        (obj) => obj.pharse === pharse.pharse
      );
      let videoIndex = pharse.videoList.findIndex(
        (obj) => obj._id === video._id
      );
      setPharse(data.lesson[pharseIndex]);
      setVideo(data.lesson[pharseIndex].videoList[videoIndex]);
    }
  };
  const loadData = () => {
    dispatch(getDetailScheduleCourseOnlineAction("668f9a1d5948901f707cd447"));
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleRegister = async (e, pharse) => {
    e.preventDefault();
    if (!token) {
      navigate("/dang-nhap", { state: { from: location } });
    } else {
      // xác nhận thông tin & chuyển tới cskh
      const data = _.cloneDeep(userInfo);
      data["tags"] = ["register-tower1", pharse];
      data["import_by_tag_name"] = true;
      await axios({
        method: "POST",
        url: "https://api.service.ladiflow.com/1.0/customer/create-or-update",
        headers: {
          "Api-Key": "a21928473afdf60440c8adddec916036aac285ce560b0133",
        },
        data,
      })
        .then((response) => {
          setOpenModal(true);
          setAnswerQuestion(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleSendAnswer = (selectedAnswer) => {
    let newData = _.cloneDeep(data);
    // find pharse Index && set selectedAnswer to answerList
    let pharseIndex = newData.lesson.findIndex(
      (obj) => obj.pharse === pharse.pharse
    );
    if (pharseIndex !== -1) {
      newData.lesson[pharseIndex].videoList.forEach((vid) => {
        if (vid._id === video._id) {
          vid.answerList = selectedAnswer;
        }
      });
    }

    // check answer all of pharse
    let checkAnswer = newData.lesson[pharseIndex].videoList?.filter((obj) =>
      isEmpty(obj.answerList)
    ).length;
    if (checkAnswer === 0) {
      dispatch(handleSendEmailAfterAnswer(userInfo, pharse.pharse));
    }
    dispatch(
      handleUserAnswerToQuestion(
        "668f9a1d5948901f707cd447",
        userInfo._id,
        newData.lesson,
        setSendAnswer
      )
    );
  };

  const handleRedirect = () => {
    navigate("/dang-nhap", { state: { from: location } });
  };

  const handleVideo = (videoItem) => {
    setVideo(videoItem);
    setAnswerQuestion(false);
    setSendAnswer(false);
  };

  const renderModal = () => {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      // width: 1000,
      // maxHeight: "100%",
      bgcolor: "background.paper",
      border: "1px solid #000",
      boxShadow: 24,
      p: 4,
      zIndex: 9999,
    };
    return (
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        <Box sx={style}>
          {answerQuestion ? (
            <div className="answer-question">Trả lời câu hỏi</div>
          ) : (
            <div className="title-h2">
              Cám ơn bạn đã đăng ký. Soul retreats sẽ phản hồi trong vòng 24h
              làm việc
            </div>
          )}
        </Box>
      </Modal>
    );
  };

  const renderItem = (videoItem, index) => {
    const hours = Math.floor(videoItem.duration / 3600);
    const minutes = Math.floor((videoItem.duration % 3600) / 60);
    const second = (videoItem.duration % 3600) % 60;
    return (
      <div key={index}>
        <div
          className={
            "p-3 d-flex justify-content-between pointer " +
            (videoItem.name === video.name && "isLearning ") +
            (videoItem.answerList && " isLearned")
          }
          onClick={() => {
            handleVideo(videoItem);
          }}
        >
          <div className="d-flex " style={{}}>
            <FlightTakeoffIcon className={"mr-2 icon-gradient-" + index} />
            Bài học
            {" " + `${index + 1}` + ": " + videoItem.name}
          </div>
          <div className="" style={{ minWidth: 70, textAlign: "end" }}>
            ({!!hours && hours + ":"}
            {!!minutes && minutes + ":"}
            {!!second && +second})
          </div>
        </div>
      </div>
    );
  };
  const renderVideo = () => {
    return (
      <div>
        <div className="title-h2 text-center pt-5">
          Xem hết Video & Trả lời câu hỏi trắc nghiệm để nhận quà hấp dẫn bạn
          nhé!
        </div>
        <div className="description-gray text-center text-danger">
          Lưu ý: Chỉ ghi nhận câu trả lời 1 lần duy nhất
        </div>
        {!answerQuestion && !video.answerList && (
          <div className="d-flex justify-content-center pointer ">
            <Button
              variant="contained"
              className={"button-primary  my-3  background-lesson-1 "}
              style={{
                fontSize: "16px",
                // bottom: "80px",
                // right: "50px",
              }}
              onClick={() => {
                setAnswerQuestion(true);
              }}
            >
              Câu hỏi trắc nghiệm
            </Button>
          </div>
        )}

        <Vimeo
          video={video?.url}
          allow="autoplay; fullscreen; picture-in-picture"
          title="Mối quan hệ hoàn hảo"
          frameborder="0"
          //   paused={paused}
          onEnd={(e) => {
            if (document.webkitCancelFullScreen) {
              document.webkitCancelFullScreen();
            }
            if (!video.answerList) setAnswerQuestion(true);
          }}
          onPlay={() => console.log("onPlay")}
          onPlaying={() => console.log("onPlaying")}
          onPause={() => console.log("onPause")}
          onSeeked={() => {
            console.log("onSeeked");
          }}
          className="box p-5"
          onTimeUpdate={(e) => {
            // console.log("onTimeUpdate", e.seconds, video.testAt);
            // e.seconds >= video.testAt && setShowModal(true);
          }}
          responsive
        />
      </div>
    );
  };
  const renderLeftContent = () => {
    return (
      <div
        className="col-lg-3 col-md-4 col-sm-12 col-xs-12 py-3"
        style={{
          borderRight: "1px solid gray",
          borderBottom: "1px solid gray",
        }}
      >
        <div
          className="d-flex align-items-center p-2 "
          style={{
            borderBottom: "1px solid gray",
            color: "rgba(4, 99, 195, 1)",
          }}
        >
          <ArrowBackIcon
            sx={{ color: "gray" }}
            className="pointer mr-3"
            onClick={() => {
              setPharse(data.lesson);
            }}
          />
          {pharse?.length > 0 ? (
            <p
              className="title-h2 "
              style={{
                marginBottom: 0,
                // color: "white",
                textTransform: "uppercase",
              }}
            >
              Chọn Phần Học
            </p>
          ) : (
            <div>
              <p style={{ marginBottom: 0 }}>{pharse?.pharse}</p>
              <p
                className="title-h2 "
                style={{
                  marginBottom: 0,
                  // color: "white",
                  textTransform: "uppercase",
                }}
              >
                {pharse.title}
              </p>
            </div>
          )}
        </div>

        {pharse?.length > 0
          ? // render pharse
            pharse.map((item, index) => {
              let idx = item.videoList.filter((obj) => isEmpty(obj._id)).length;
              return (
                <div className="mt-3" key={index}>
                  <p
                    style={{ marginBottom: 0 }}
                    className={"icon-gradient-" + index}
                  >
                    {item.pharse}
                  </p>
                  <Button
                    variant="contained"
                    className={
                      "button-primary mr-2 mt-3 animate-bounce  background-lesson-" +
                      1
                    }
                    style={{
                      fontSize: "16px",
                    }}
                    onClick={(e) => {
                      idx === 0 ? (
                        item.permission ? (
                          setPharse(data.lesson[index])
                        ) : (
                          handleRegister(e, item.pharse)
                        )
                      ) : (
                        <></>
                      );
                    }}
                    // disabled={!item.permission}
                  >
                    {item.title}
                    {!item.permission && (
                      <>
                        &nbsp;-&nbsp; <FormatNumberic num={item.price} />
                      </>
                    )}
                  </Button>
                </div>
              );
            })
          : // render video in pharse
            pharse?.videoList?.map((item, index) => {
              return renderItem(item, index);
            })}
      </div>
    );
  };
  const renderRightContent = () => {
    console.log("pharse", pharse);
    return (
      <div className="col-lg-9 col-md-8 col-sm-12 col-xs-12 min-vh-100 ">
        {pharse?.length > 0 ? (
          <div className="d-flex align-items-center justify-content-center min-vh-100">
            <div
              className="h1"
              style={{ color: "red", textTransform: "uppercase" }}
            >
              Vui lòng chọn phần học để tiếp tục xem video
            </div>
          </div>
        ) : pharse.permission ? (
          <>
            {answerQuestion ? (
              <Quiz
                data={data}
                video={video}
                pharse={pharse}
                handleSendAnswer={(selectedAnswer) =>
                  handleSendAnswer(selectedAnswer)
                }
              />
            ) : (
              renderVideo()
            )}
          </>
        ) : (
          <div className="d-flex align-items-center justify-content-center min-vh-100">
            <div
              className="h1"
              style={{ color: "red", textTransform: "uppercase" }}
            >
              Bạn Chưa có quyền xem Video này
            </div>
          </div>
        )}
      </div>
    );
  };
  return data?.lesson?.length > 0 ? (
    <Box sx={{ display: "flex" }} className="video-streaming">
      <div className="container-fluid">
        <div className="row">
          {renderLeftContent()}
          {renderRightContent()}
        </div>
      </div>
      {renderModal()}
    </Box>
  ) : (
    <Loading count={11} />
  );
};

export default VideoStreaming;
