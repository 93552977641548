import React, { useState } from "react";
import "./styles.scss";
import lightBackground from "assets/images/source/course-offline/kts/lightbackground.jpg";
import starBackground from "assets/images/source/course-offline/kts/starbackground.png";
import banner from "assets/images/source/course-offline/kts/banner.jpg";
import point from "assets/images/source/course-offline/kts/point.png";
import coachVas02 from "assets/images/source/course-offline/1st/coach-vas-02.png";
import coachVas03 from "assets/images/source/course-offline/1st/coach-vas-03.png";
import coachThanh from "assets/images/source/course-offline/1st/coach-thanh.png";
import coachThanh03 from "assets/images/source/course-offline/1st/coach-thanh-03.png";
import Timeline from "app/modules/ClientModules/lpe/mrVas/components/timeline";
import banner02 from "assets/images/source/course-offline/1st/banner-2.png";
import { Fade } from "react-awesome-reveal";
import { Button } from "@material-ui/core";
import { Box, Modal } from "@mui/material";

import RegisterForm from "share/registerForm";
const array = [
  "Chủ Doanh Nghiệp/CEO",
  "Giám Đốc - Phó Giám Đốc",
  "Viện Trưởng - Phó Viện Trưởng",
  "Hiệu Trưởng - Phó Hiệu Trưởng",
  "Các vị trí lãnh đạo cấp cao khác",
];
const because1 = [
  "Công ty không tìm ra khách hàng",
  "Công ty chưa Phát Triển Đồng Bộ",
  "Thiếu May Mắn - Chưa Thấy Thời Cơ Tới",
  "Thị Trường Bất Ổn - Công ty chịu ảnh hưởng tài chính",
];
const because2 = [
  "Luôn phải lo về Nợ Xấu",
  "Chưa thể quay vòng đợi vốn vay",
  "Đội Ngũ Nhân Lực Thiếu Sáng Tạo, Năng Suất Kém",
  "Doanh số chưa cao để mở rộng hoặc tái thiết lập công ty",
];

const inforCoachThanh = [
  "Chứng nhận Master Trainer ( được cấp bởi NLP UNIVERSITY & ABNLP)",
  "Nhà trị liệu thôi miên – Được phê duyệt bởi HỘI ĐỒNG TRỊ LIỆU THÔI MIÊN HOA KỲ",
  "Chuyên gia trị liệu dòng thời gian (Được phê duyệt bởi HIỆP HỘI TRỊ LIỆU DÒNG THỜI GIAN)",
  "Chuyên gia khai phá tiềm năng vô thức (được chứng nhận bởi IAGC)",
  "Chuyên gia đào tạo và tư vấn doanh nghiệp",
  "Chuyên gia quốc tế về tư vấn trị liệu cá nhân (COACHING 1-1) chuyên sâu về các vấn đề cho các khách hàng cá nhân và chủ doanh nghiệp.",
  "14 năm kinh nghiệm trong lĩnh vực phát triển bản thân đặc biệt là Lập Trình Ngôn Ngữ Tư Duy (NLP)-người tiên phong trong việc xây dựng định hình thị trường cho NLP & Khoa học Tâm trí để trở thành một dịch vụ có giá trị cao.",
  "Đào tạo trực tiếp hàng nghìn khách hàng từ trẻ em đến người lớn, các bạn sinh viên, công nhân viên chức, người lao động, đến chủ doanh nghiệp trên khắp Việt Nam.",
];
const forWho = "Khóa học dành cho ai";
const because = "Nguyên nhân chúng ta đang đối mặt với các khủng hoảng";
const value = [
  "Học với Chuyên gia Huấn Luyện Quốc Tế Coach Vas",
  "Học với Chuyên gia Tâm Lý Quốc Tế Coach Thanh",
  "Nắm bắt tâm lý trong đàm phán và kinh doanh",
  "Cập nhật xu hướng chuyển đổi số và tư duy số vào hoạt động vận hành doanh nghiệp",
  "Quản trị nhân sự hiện đại một cách có hệ thống",
  "Định hình văn hoá doanh nghiệp và truyền cảm hứng cho nhân viên, từ đó nâng cao hiệu suất làm việc",
  "Phát triển kỹ năng quản lý và tư duy lãnh đạo xuất chúng",
];

function KTS(props) {
  const [openModal, setOpenModal] = useState(false);
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});
  const storedParams = localStorage.getItem("utm");
  const parsedParams = JSON.parse(storedParams);
  const handleRedirectZalo = () => {
    window.open("https://zalo.me/0906880917", "_blank");
  };
  const renderTrainer = () => {
    return (
      <div className="kts-trainer">
        <div className="container">
          <div className="mb-5 kts-trainer-coachvas">
            <div
              className="section-background"
              style={{
                backgroundImage: "url(" + banner02 + ")",
                backgroundSize: "cover",
                borderRadius: "20px",
              }}
            ></div>
            <Timeline view="tfs" />

            <div className="kts-coachvas-content">
              <img
                src={coachVas02}
                alt="coach vas 02"
                className="img-coachvas-02 "
              />
              <img
                src={coachVas03}
                alt="Coach Vas"
                className="img-coachvas-03"
              />
            </div>
          </div>
          <div className="kts-trainer-coach-thanh ">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-3 d-flex align-items-center justify-content-center ">
                <div className="d-flex justify-content-center">
                  <img src={coachThanh} alt={coachThanh} />
                </div>
                <div className="position-absolute" style={{ bottom: 50 }}>
                  <img src={coachThanh03} alt={coachThanh03} width={350} />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12  ">
                <div className="right-content">
                  <img
                    src={starBackground}
                    alt={starBackground}
                    className="kts-trainer-coach-thanh-img"
                  />
                  <div className="headline-h3 mb-4 text-left">Thành Tựu</div>
                  {inforCoachThanh.map((inf, index) => {
                    return (
                      <Fade cascade duration={3000}>
                        <div key={index} className="d-flex  mb-2">
                          <div className="headline-h4 mr-3">0{index + 1}</div>
                          <div className="kts-trainer-text">{inf}</div>
                        </div>
                      </Fade>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderSwitch = () => {
    switch (step) {
      case 0:
        return (
          <RegisterForm
            title={"Thông tin đăng ký !"}
            type={"KeyToSuccess"}
            setStep={(i) => {
              setStep(i);
            }}
            setData={(i) => {
              setData(i);
            }}
            parsedParams={parsedParams}
          />
        );
      case 1:
        return (
          <div className="row popup">
            <div className="popup-background"></div>
            <div className="overlay" />
            <div className="col-12 position-relative">
              <div className="px-5 py-5">
                <div className="row">
                  <h2>CÁM ƠN BẠN </h2>
                  <h4>ĐÃ ĐĂNG KÝ CHƯƠNG TRÌNH</h4>
                  <h3>CHÌA KHÓA THÀNH CÔNG</h3>
                </div>
                <div className="row mt-4 d-flex justify-content-center">
                  <h4 style={{ color: "yellow" }}>HOÀN THÀNH ĐĂNG KÝ TƯ VẤN</h4>
                  <p> Chúng tôi sẽ sớm liên hệ lại với bạn</p>
                  <p>Liên hệ hotline để được giải đáp thắc mắc</p>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };
  const renderPopUp = () => {
    const style = {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "40px",
      boxShadow: 24,
      position: "absolute",
      display: "block",
      width: "100%",
    };
    return (
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setStep(0);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          borderRadius: "40px",
        }}
      >
        <Box sx={style}>
          <div
            className="container "
            style={{ overflow: "auto", height: "80vh" }}
          >
            <div className="position-absolute" style={{ zIndex: 1 }}>
              <Button
                onClick={() => {
                  setOpenModal(false);
                  setStep(0);
                }}
                className="kts-close-modal"
              >
                X
              </Button>
            </div>
            {renderSwitch()}
          </div>
        </Box>
      </Modal>
    );
  };
  const renderFixedBottom = () => {
    return (
      <div className="kts-fixed-bottom">
        <div className="container potision-relative">
          <div className="img-register">
            <Button
              className=" kts-btn-register animate-bounce"
              onClick={() => {
                setOpenModal(true);
                setStep(0);
              }}
            >
              Đăng ký
            </Button>
          </div>
          <div className="mt-3  kts-fixed-bottom-content col-8 offset-2">
            <p className="title-h2 text-center">
              Tặng ngay 5.000.000Đ cho học viên đã từng tham gia các khóa học
              của Coach Vas
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="kts">
      <img src={banner} alt={banner} style={{ width: "100%" }} />
      <div className="kts-target-audient">
        <div className="container  pt-5">
          <div className="position-relative">
            <img src={point} alt={point} className="kts-point" />
          </div>
          <div className="headline-h2 " style={{ textAlign: "left" }}>
            {forWho}
          </div>
          <div className="row justify-content-center d-flex">
            {array.map((item, index) => {
              return (
                <div
                  key={index}
                  className="col-lg-4 col-md-4 col-sm-10 col-xs-10 mt-3 animate-fade"
                >
                  <Fade cascade duration={4000}>
                    <div className="box">
                      <div
                        className="section-background"
                        style={{
                          backgroundImage: "url(" + lightBackground + ")",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          opacity: 0.4,
                        }}
                      ></div>
                      <div className="overlay" />
                      <div className="position-relative ">
                        <span>0{index + 1}</span>
                        {item}
                      </div>
                    </div>
                  </Fade>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="kts-experience pb-5">
        <div className="container pt-5">
          <div className="wrap mb-3">
            <div className="line">
              <Fade>
                <div className="headline-h2 ">{because}</div>
              </Fade>
            </div>
          </div>
          <div className="row kts-experience-content">
            <div className="col-6 kts-experience-left-content">
              <Fade cascade duration={1000}>
                {because1.map((item, index) => {
                  return (
                    <div
                      className="d-flex justify-content-end mb-3 text-end"
                      key={index}
                    >
                      {item}
                    </div>
                  );
                })}
              </Fade>
            </div>
            <div className="col-6 kts-experience-right-content">
              <Fade cascade duration={1000}>
                {because2.map((item, index) => {
                  return (
                    <div
                      className="d-flex justify-content-start mb-3"
                      key={index}
                    >
                      {item}
                    </div>
                  );
                })}
              </Fade>
            </div>
          </div>
        </div>
      </div>
      {renderTrainer()}
      <div className="kts-value pb-5">
        <div className="container">
          <div className="box">
            <div className="headline-h2">Mục tiêu đột phá của khóa học</div>
            <div className="kts-value-content">
              {value.map((item, index) => {
                return (
                  <Fade cascade duration={3000}>
                    <p key={index}>{item}</p>
                  </Fade>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {renderFixedBottom()}
      {renderPopUp()}
    </div>
  );
}

export default KTS;
