import { useState } from "react";
import axios from "axios";
// import { Redirect } from "react-router-dom";
// import useSiteTitle from "core/hooks/useSiteTitle";

import * as Yup from "yup";
import { TheFirstStepSchema } from "app/const/yup";
import { FastField, Form, Formik } from "formik";

import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import InputField from "app/components/customField/inputField";
import SelectField from "app/components/customField/selectField";
import DatePickerField from "app/components/customField/datePickerField";
// import { timeToUnix } from "core/utils/dateUtil";
import { DEFALT_OPTIONS } from "app/components/customField/selectField/options";
// import { API_ENDPOINT, CODE_SUCCESS, SIGN_UP } from "app/const/Api";
// import { KEY_TOKEN } from "app/const/App";
import tfs from "assets/images/source/course-offline/1st/tfs.png";
import "./styles.scss";
import { isEmpty } from "lodash";
import { addCourseToCart } from "core/redux/actions/cartAction";
import { useDispatch } from "react-redux";

const ButtonSubmit = styled(Button)`
  color: #fff;
  font-size: 17px;
  background-image: linear-gradient(rgb(255, 0, 191), #8420be);
  :hover {
    color: #fff;
    background-image: linear-gradient(#8420be, rgb(255, 0, 191));
  }
`;

function RegisterForm(props) {
  //   const isLogined = Boolean(localStorage.getItem(KEY_TOKEN));
  // const [step, setStep] = useState(0);
  const step = 0;
  const [loading, setLoading] = useState(false);
  const { title, ticket } = props;
  const dispatch = useDispatch();
  // const [error, setError] = useState("");

  const handleRegister = async (data) => {
    props?.setStep(1);
    props?.setData(data);
    const price =
      data.loaive === "Vip"
        ? 3600000
        : data.loaive === "Platinum"
        ? 3000000
        : 199000;
    let product = {
      type: "courseOffline",
      name: "The Restart - Tái Thiết Thịnh Vượng",
      course: "theRestart",
      img: tfs,
      data,
      ticket: data.loaive,
      count: 1,
      price: price,
      checkout: false,
    };

    // dispatch(addCourseToCart(product));
    setLoading(true);
    handleSendData(data);
  };

  const handleSendData = async (data) => {
    const parsedParams = props.parsedParams ? props.parsedParams : {};

    if (props?.type === "KeyToSuccess") {
      data["tags"] = ["KeyToSuccess"];
    } else {
      data["tags"] = ["Vas-TheFirstStep"];
    }

    data["import_by_tag_name"] = true;
    const finalData = {
      ...data,
      ...parsedParams,
    };

    await axios({
      method: "POST",
      url: "https://api.service.ladiflow.com/1.0/customer/create-or-update",
      headers: {
        "Api-Key": "a21928473afdf60440c8adddec916036aac285ce560b0133",
      },
      data: finalData,
    })
      .then((response) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const initialValues = {
    first_name: "",
    email: "",
    phone: "",
    dob: 33756000,
    loaive:
      ticket === "VIP" ? "Vip" : ticket === "PLATINUM" ? "Platinum" : "General",
    address: "",
    tunghoc: "",
    songuoidicung: 0,
  };

  // const initialValues = {
  //   dob: 33756000,
  //   email: "ttkien94@gmail.com",
  //   gender: "Nam",
  //   first_name: "trinh trung kien",
  //   loaive:
  //     ticket === "VIP" ? "Vip" : ticket === "PLATINUM" ? "Platinum" : "General",
  //   phone: "0383204367",
  //   songuoidicung: 0,
  //   address: "",
  // };
  const validationSchema = Yup.object().shape(TheFirstStepSchema);

  const RenderUI = (step) => {
    switch (step) {
      case 0:
        return (
          <div
            className="registerForm row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div className="frame-bg">
              <h3 className=" pt-2  header-title">{title}</h3>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleRegister}
              enableReinitialize
            >
              {(formikProps) => {
                const { values, errors, touched } = formikProps;

                console.log({ values, errors, touched });

                return (
                  <Form
                    className="row mt-4 py-3 form-body"
                    style={{
                      maxWidth: "700px",
                    }}
                  >
                    <div className="col-12 col-md-6">
                      <FastField
                        name="first_name"
                        component={InputField}
                        label="Tên đầy đủ"
                        placeholder="Nhập tên"
                        className="w-100 mb-4"
                      />
                    </div>

                    <div className="col-12 col-md-6">
                      <FastField
                        name="email"
                        component={InputField}
                        label="Email"
                        placeholder="Nhập email"
                        className="w-100 mb-4"
                      />
                      {/* {errors?.email && (
                        <p className="text-danger mb-4 ml-2 MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root mt-n3 ">
                          {errors.email}
                        </p>
                      )} */}
                    </div>

                    <div className="col-12 col-md-6">
                      <FastField
                        name="phone"
                        component={InputField}
                        label="Số điện thoại"
                        placeholder="Nhập số điện thoại"
                        className="w-100 mb-4"
                        autocomplete
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <FastField
                        name="tunghoc"
                        component={SelectField}
                        label="Đã từng học các khóa The Origins"
                        className="w-100 mb-4"
                        options={DEFALT_OPTIONS.tunghoc}
                      />
                    </div>
                    {props?.type === "KeyToSuccess" ? (
                      <></>
                    ) : (
                      <>
                        <div className="col-12 col-md-6 mb-2">
                          <FastField
                            name="dob"
                            component={DatePickerField}
                            label="Ngày sinh"
                            placeholder="Nhập ngày sinh"
                            className="w-100 mb-2 mb-md-4"
                          />
                          {errors.dob && (
                            <p className=" text-danger MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root mt-n3">
                              {errors.dob}
                            </p>
                          )}
                        </div>
                        <div className="col-12 col-md-6">
                          <FastField
                            name="loaive"
                            component={SelectField}
                            label="Loại vé"
                            // placeholder="Nhập giới tính"
                            className="w-100 mb-4"
                            options={DEFALT_OPTIONS.loaive}
                          />
                        </div>

                        <div className="col-12 col-md-6">
                          <FastField
                            name="address"
                            component={InputField}
                            label="Địa chỉ"
                            placeholder="Nhập địa chỉ"
                            className="w-100 mb-4"
                          />
                          {/* {errors && (
                        <p className="text-danger mb-4 ml-2 MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root mt-n3 ">
                          {errors}
                        </p>
                      )} */}
                        </div>

                        <div className="col-12 col-md-6">
                          <FastField
                            name="songuoidicung"
                            component={InputField}
                            label="Số người đi cùng"
                            placeholder="Số người đi cùng"
                            className="w-100 mb-4"
                            autocomplete
                          />
                        </div>
                      </>
                    )}
                    <div className="col-12 justify-content-center d-flex">
                      <ButtonSubmit
                        type="submit"
                        className=""
                        disabled={
                          isEmpty(errors) && values.email ? false : true
                        }
                      >
                        Đăng ký
                        {loading && <div className="loader ml-1"></div>}
                      </ButtonSubmit>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        );
      case 1:
        return (
          <>
            <div className="OTPContainer">
              <div className="formContainerOTP">
                <div className="formHeading">
                  <h5>Soul Retreats đã gửi một Email xác thực đến cho bạn</h5>
                </div>

                <p className="descText px-4">
                  Vui lòng kiểm tra hộp thư đến, có thể Email nằm trong mục spam
                  của bạn.
                </p>
              </div>
            </div>
          </>
        );

      default:
        throw new Error("Invalid Screen!!!");
    }
  };

  return RenderUI(step);
}

export default RegisterForm;
