import { useTranslation } from "react-i18next";

export const arrayFowWho = [
  {
    bg: "https://w.ladicdn.com/source/illustration/reviews-02.svg",
    title: "Mong muốn thành công nhưng sợ thất bại",
  },
  {
    bg: "https://w.ladicdn.com/source/illustration/reviews-02.svg",
    title: "Mất tập trung, dễ chán nản, lười biếng và trì hoãn trong mọi việc",
  },
  {
    bg: "https://w.ladicdn.com/source/illustration/reviews-02.svg",
    title: "Bị stress trong công việc, học tập, gia đình",
  },
  {
    bg: "https://w.ladicdn.com/source/illustration/reviews-02.svg",
    title: "Tâm trạng thất thường, trầm cảm, dễ tổn thương",
  },
  {
    bg: "https://w.ladicdn.com/source/illustration/reviews-02.svg",
    title: "Chưa có định hướng và mục tiêu rõ ràng cho sự nghiệp, tương lai",
  },
];
export const arrayPrice = [
  {
    title: "GENERAL",
    benefit: ["Tài liệu học tập", "Tặng khóa học Online (Bí Mật 3 Tâm Trí)"],
    defaultPrice: "399.000 Đ",
    promoPrice: "199.000 Đ",
    // promoText: "Giảm 50%",
    warningText:
      "Đăng ký chuyển khoản ngay để nhận được khóa học online ngay lập tức",
  },

  {
    title: "PLATINUM",
    benefit: [
      "Tài liệu học tập",
      "Tặng Gift Voucher : 500.000 Đ",
      "Ngồi hàng ghế gần với chuyên gia sau V.I.P",
    ],
    defaultPrice: "5.999.000 Đ",
    promoPrice: "3.000.000 Đ",
    // promoText: "3 NGƯỜI TRỞ LÊN",
    // promoText2: "CHỈ CÒN 1.599.000 VNĐ/ NGƯỜI",
    warningText:
      "Đăng ký chuyển khoản ngay để nhận được khóa học online ngay lập tức",
  },
  {
    title: "VIP",
    benefit: [
      "Tài liệu học tập",
      "Checkin lối riêng",
      "Teabreak",
      "Ghế ngồi hạng VIP",
      "Cơ hội đặt câu hỏi với chuyên gia",
      "Tặng Gift Voucher : 1.000.000 Đ",
    ],
    defaultPrice: "6.999.000 đ",
    promoPrice: "3.600.000 đ",
    // promoText: "Giảm 50%",
    // promoText2: "CHỈ CÒN 3.199.000 VNĐ/ NGƯỜI",
    warningText:
      "Đăng ký chuyển khoản ngay để nhận được khóa học online ngay lập tức",
  },
];
export const arrayArchiment = [
  {
    number: "+13",
    title: "Khóa học",
    desc: "Khai phá tiềm năng bản thân",
  },
  {
    number: "+650K",
    title: "Học viên",
    desc: "Trở thành phiên bản tốt nhất",
  },
  {
    number: "+50Y",
    title: "Kinh nghiệm",
    desc: "Giảng dạy trên thế giới",
  },
];
export const arrayReason = [
  {
    number: "13",
    title: "Khóa học",
    desc: "Trị Liệu Tâm Lý",
    desc2: "Chăm Sóc Sức Khỏe Toàn Diện",
  },
  {
    number: "33%",
    title: "Khóa học",
    desc: "Xây Dựng Lối Sống",
    desc2: "Lành Mạnh",
  },
  {
    number: 3,
    title: "Khóa học",
    desc: "Thay Đổi ",
    desc2: "Tư Duy Tích Cực",
  },
];
export const quoteVas = [
  {
    eng: "𝐀𝐋𝐖𝐀𝐘𝐒 𝐇𝐀𝐕𝐄 𝐀 𝐏𝐎𝐒𝐈𝐓𝐈𝐕𝐄 𝐌𝐈𝐍𝐃𝐒𝐄𝐓 𝐀𝐍𝐃 𝐁𝐄 𝐑𝐄𝐀𝐃𝐘 𝐓𝐎 𝐅𝐀𝐂𝐄 𝐀𝐍𝐘 𝐃𝐈𝐅𝐅𝐈𝐂𝐔𝐋𝐓𝐈𝐄𝐒",
    vn: "Luôn có tư duy tích cực và sẵn sàng đối mặt với mọi khó khăn",
  },
  {
    eng: "𝐓𝐎 𝐌𝐀𝐊𝐄 𝐘𝐎𝐔𝐑 𝐏𝐑𝐄𝐒𝐄𝐍𝐓 𝐃𝐈𝐅𝐅𝐄𝐑𝐄𝐍𝐓, 𝐈𝐌𝐀𝐆𝐈𝐍𝐄 𝐘𝐎𝐔𝐑 𝐅𝐔𝐓𝐔𝐑𝐄",
    vn: "Để hiện tại của bạn khác đi hãy hình dung tưởng tượng về tương lai của bạn",
  },
  {
    eng: " 𝐒𝐏𝐄𝐍𝐃 𝐌𝐎𝐑𝐄 𝐓𝐈𝐌𝐄 𝐏𝐑𝐀𝐈𝐒𝐈𝐍𝐆 𝐀𝐍𝐃 𝐋𝐎𝐕𝐈𝐍𝐆 𝐘𝐎𝐔𝐑𝐒𝐄𝐋𝐅 𝐓𝐇𝐀𝐍 𝐂𝐑𝐈𝐓𝐈𝐂𝐈𝐙𝐈𝐍𝐆 𝐀𝐍𝐃 𝐉𝐔𝐃𝐆𝐈𝐍𝐆",
    vn: "Hãy tự khen ngợi và yêu thương bản thân hơn là chỉ trích và phê phán",
  },
  {
    eng: " 𝗣𝗘𝗢𝗣𝗟𝗘 𝗔𝗟𝗪𝗔𝗬𝗦 𝗟𝗜𝗠𝗜𝗧 𝗧𝗛𝗘𝗠𝗦𝗘𝗟𝗩𝗘𝗦 𝗪𝗜𝗧𝗛 𝗛𝗔𝗕𝗜𝗧𝗦 𝗧𝗛𝗔𝗧 𝗧𝗛𝗘𝗬 𝗛𝗔𝗩𝗘 𝗕𝗘𝗘𝗡 𝗗𝗢𝗜𝗡𝗚 𝗙𝗢𝗥 𝗔 𝗟𝗢𝗡𝗚 𝗧𝗜𝗠𝗘",
    vn: "Con người luôn tự giới hạn mình bằng những thói quen cũ",
  },
];
export const quoteThanh = [
  "Hành động hiện tại của chúng ta bây giờ là vô vàn trải nghiệm từ quá khứ",
  "Con người luôn luôn tự giới hạn mình bằng thói quen lâu nay vẫn hay làm",
  "Đừng chờ ai đó thay đổi cuộc đời bạn, chính bạn là người thay đổi chính mình",
  "Nỗ lực nâng cấp bản thân, tìm kiếm cơ hội bằng việc mở lòng đón nhận những góc nhìn mới",
];
