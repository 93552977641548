export const DEFALT_OPTIONS = {
  gender: [
    {
      value: "Nam",
      name: "Nam",
    },
    {
      value: "Nữ",
      name: "Nữ",
    },
  ],
  loaive: [
    {
      value: "Vip",
      name: "Vip",
    },
    {
      value: "Platinum",
      name: "Platinum",
    },
    {
      value: "General",
      name: "General",
    },
  ],
  tunghoc: [
    {
      value: "Đã từng",
      name: "datung",
    },
    {
      value: "Chưa",
      name: "chua",
    },
  ],
  role: [
    {
      value: "admin",
      name: "Admin",
    },
    {
      value: "client",
      name: "Client",
    },
  ],
};
